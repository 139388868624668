"use client";

import OptimizedImage from "@/components/NextImage";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { easeInOut, easeOut, motion, useScroll, useSpring, useTransform } from "framer-motion";
import Link from "next/link";
import { useEffect, useState } from "react";

export default function Hero() {
    const { scrollY } = useScroll();
    const [windowHeight, setWindowHeight] = useState<number>(0);
    const [loaded, setLoaded] = useState(false);

    const bgY = useTransform(
        scrollY,
        [0, windowHeight],
        [0, windowHeight * 0.6],
        {
            // ease: easeInOut
        }
    );

    const textY = useTransform(
        scrollY,
        [0, windowHeight],
        [0, windowHeight * 0.2]
    );

    useEffect(() => {
        function handleResize() {
            setWindowHeight(window.innerHeight);
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="relative flex h-[97vh] flex-col items-center overflow-hidden bg-black">
            <motion.div
                className="absolute"
                initial={{
                    opacity: 0,
                    width: "150%",
                    height: "150%",
                }}
                animate={
                    loaded
                        ? {
                            opacity: 1,
                            width: "100%",
                            height: "100%",
                            transition: {
                                duration: 0.3,
                            },
                        }
                        : undefined
                }
                style={{
                    y: bgY,
                }}
            >
                <OptimizedImage
                    className="h-full"
                    src={"/images/hero_5.jpg"}
                    alt="primo piano birra in calice"
                    objectFit="cover"
                    sizes="(min-width: 700px) 200vw, (min-width: 1024px) 150vw, 300vw"
                    setLoaded={setLoaded}
                />
            </motion.div>

            <div className="absolute top-0 h-full w-full bg-gradient-to-tl from-black to-transparent to-60% md:bg-gradient-to-l" />
            <div className="absolute top-0 h-full w-full bg-gradient-to-tl from-black to-transparent to-60% md:bg-gradient-to-l">
                <motion.div
                    className="absolute bottom-[10%] right-0 rounded-lg px-6 pt-10 text-background md:bottom-[20%] md:right-[5%] md:pt-16"
                    style={{
                        y: textY,
                    }}
                >
                    <h1 className="font-extraligh mb-8 font-display text-3xl italic leading-tight tracking-widest xl:text-[2vw]">
                        Fraternità e<br />
                        condivisione
                        <br />
                        <span className="text-heroAccent">al sapor di birra</span>
                    </h1>
                    {/* Il valore<br />della solidarietà e<br />della condivisione<br /><span className="text-heroAccent">al sapor di birra</span></h1> */}

                    <Link
                        href={"/shop"}
                        className={cn(
                            buttonVariants({ variant: "secondary" }),
                            "bg-heroAccent text-heroAccent-foreground shadow"
                        )}
                    >
                        Vai allo shop
                    </Link>
                </motion.div>
            </div>
        </div>
    );
}
